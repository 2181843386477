
import Vue from "vue";
import { ProgramsManager } from "@/services/ProgramsManager";
import { Form, FormBuilder } from "@memberpoint/mp-components";
import AppHttpResource from "@/lib/AppHttpResource";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "ProgramSessionChecklistCompleteForm",
  props: {
    programId: {
      type: String,
      required: true,
    },

    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadError: null as string | null,
      form: null as Form | null,
      submitting: false,
      submitError: null as string | null,
    };
  },
  created() {
    this.loadForm();
  },
  methods: {
    clear(): void {
      this.loading = false;
      this.submitting = false;
      this.submitError = null;
      this.loadError = null;
      this.form = null;
    },

    getFormRef(): InstanceType<typeof FormBuilder> | null {
      if (!this.$refs.form) {
        return null;
      }

      return this.$refs.form as InstanceType<typeof FormBuilder>;
    },

    loadForm(): void {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.loadError = null;
      const programManager = new ProgramsManager();

      programManager
        .getProgramSessionChecklistForm(this.programId, this.sessionId)
        .then((form) => {
          this.form = form;
        })
        .catch((response) => {
          const errors = ApiResponseHelper.getErrorMessagesFromResponse(
            response
          );

          if (0 < errors.length) {
            this.loadError = errors[0];
          } else {
            this.loadError =
              "An unexpected error occurred trying to load the checklist form.";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSubmitForm(): void {
      if (!this.form) {
        return;
      }

      const formRef = this.getFormRef();

      if (!formRef) {
        return;
      }

      const attributes = formRef.getAttributes();
      this.submitting = true;
      this.submitError = null;

      AppHttpResource.request({
        url: this.form.action.url,
        method: this.form.action.method,
        data: { attributes },
      })
        .then((response) => {
          if (200 === response.status) {
            this.$emit("completed");
          }
        })
        .catch((response) => {
          const errors = ApiResponseHelper.getErrorMessagesFromResponse(
            response
          );

          if (0 < errors.length) {
            this.submitError = errors[0];
          } else {
            this.submitError =
              "An unexpected error occurred trying to complete the checklist.";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
});
