import http from "@/lib/AppHttpResource";
import Configuration from "@/lib/Configuration";
import { LooseObject } from "@/types";

export class AttendanceManager {
  /**
   * Add checkin enrolment for session.
   */
  addCheckInEnrolmentForSession(
    programUuid: string,
    sessionUuid: string,
    attributes: {
      enrolmentId: string;
    }
  ): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/sessions/${sessionUuid}/checkin`;

      http
        .post(url, { attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Remove checkin enrolment for session.
   */
  removeCheckInEnrolmentForSession(
    programUuid: string,
    sessionUuid: string,
    attributes: {
      enrolmentId: string;
    }
  ): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/sessions/${sessionUuid}/checkin`;

      http
        .delete(url, {
          data: {
            attributes: attributes,
          },
        })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Add guest to session.
   */
  addGuestToSession(
    programUuid: string,
    sessionUuid: string,
    attributes: {
      type: string | null;
      label: string | null;
      age: string | null;
      gender: string | null;
      quantity: number;
      postcode: string | null;
    }
  ): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/guests/${sessionUuid}`;

      http
        .post(url, { attributes: attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Search enrolments by NIN
   */
  enrolmentSearch(programUuid: string, nin: string): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/enrolment-search`;

      http
        .post(url, { attributes: { nin: nin } })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Add enrolment to program by club member profile id.
   */
  addEnrolmentToProgramByClubMemberProfile(
    programUuid: string,
    sessionUuid: string,
    attributes: {
      memberProfileId: string;
    }
  ): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/sessions/${sessionUuid}/enrolment/by-member`;

      http
        .post(url, { attributes: attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Add enrolment to program.
   */
  createInvitation(
    programUuid: string,
    attributes: {
      firstName: string;
      lastName: string;
      emailAddress: string | null;
      mobileNumber: string | null;
    }
  ): Promise<LooseObject> {
    return new Promise<LooseObject>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/programs/marker/${programUuid}/create-invitation`;

      http
        .post(url, { attributes: attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
