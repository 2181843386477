


































































































import Vue from "vue";
import { AttendanceManager } from "@/services/AttendanceManager";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "RegisterGuestForm",
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      type: null as string | null,
      label: null as string | null,
      age: "Not Supplied" as string | null,
      postcode: null as string | null,
      gender: "NA" as string | null,
      isATSI: false,
      isDisability: false,
      isCALD: false,
      isPasifika: false,
      quantity: 1,
      errors: [] as string[],
      loading: false as boolean,
    };
  },
  computed: {
    types(): Array<{ title: string; value: string }> {
      let ar = this.$store.getters["components/guestTypes"];
      let types = [];
      for (let key in ar) {
        if (Object.prototype.hasOwnProperty.call(ar, key)) {
          types.push({ title: key, value: ar[key] });
        }
      }
      return types;
    },
    ageChoices(): Array<{ title: string; value: string }> {
      return [
        { title: "0-16", value: "0-16" },
        { title: "16-24", value: "16-24" },
        { title: "25+", value: "25+" },
        { title: "Not Supplied", value: "Not Supplied" },
      ];
    },
    genderChoices(): Array<{ title: string; value: string }> {
      return [
        { title: "Male", value: "M" },
        { title: "Female", value: "F" },
        { title: "Other", value: "O" },
        { title: "Not Supplied", value: "NA" },
      ];
    },
  },
  methods: {
    registerGuest() {
      this.loading = true;
      const attendanceManager = new AttendanceManager();
      const attributes = {
        type: this.type,
        label: this.label,
        postcode: this.postcode,
        age: this.age,
        gender: this.gender,
        quantity: this.quantity,
        isATSI: this.isATSI,
        isDisability: this.isDisability,
        isCALD: this.isCALD,
        isPasifika: this.isPasifika,
      };
      attendanceManager
        .addGuestToSession(this.programId, this.sessionId, attributes)
        .then(() => {
          this.$emit("registered");
        })
        .catch((error) => {
          this.loading = false;
          this.errors = ApiResponseHelper.getErrorMessagesFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
