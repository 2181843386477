














import Vue, { PropType } from "vue";
import HeaderImage from "@/assets/img/programs-header.png";

export default Vue.extend({
  name: "ProgramsHeader",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    bannerImage: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  computed: {
    image(): string {
      return this.bannerImage ? this.bannerImage : HeaderImage;
    },
  },
});
