































import Vue from "vue";
import Session from "@/Classes/Session";
import { formatDate } from "@/lib/DateHeler";

export default Vue.extend({
  name: "SessionSelect",
  props: {
    program: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedSessionId: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      programName: "" as string | null,
      sessions: [] as any[],
      sessionId: "" as string,
      errors: [] as string[],
    };
  },
  created() {
    this.load();
  },
  methods: {
    /* Load the program details and it's sessions */
    load() {
      // check if selected session id is passed
      if (this.selectedSessionId) {
        this.sessionId = this.selectedSessionId;
      }
      this.programName = this.program.attributes.title;
      const sessions = this.program.attributes.sessions || [];
      sessions.forEach((session: Session) => {
        if (
          session?.attributes?.title !== "" &&
          session?.attributes?.startDateTimeUtc
        ) {
          const id = session?.id;
          const formattedStartDate = formatDate(
            session?.attributes?.startDateTimeUtc,
            "MMM DD, YYYY hh:mm A"
          );
          this.sessions.push({
            title: `${session.attributes.title} - ${formattedStartDate}`,
            value: id,
          });
        }
      });

      // preselect the first session if no session is selected
      if (!this.sessionId && this.sessions.length > 0) {
        this.sessionId = this.sessions[0].value;
        this.onSessionChange();
      }
    },

    onSessionChange() {
      if (!this.sessionId) {
        return;
      }

      // emit the session id
      this.$emit("session-change", this.sessionId);
    },
  },
});
