import dayjs, { ConfigType } from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import timezonePlugin from "dayjs/plugin/timezone";

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

/**
 * Formats the given date to the given format.
 *
 * @param date
 * @param format
 * @param timezone
 */
export function formatDate(
  date: ConfigType,
  format: string,
  timezone: string | null = null
): string {
  let dateObj = dayjs(date);

  if (!dateObj.isValid()) {
    return "";
  }

  if (timezone !== null) {
    dateObj = dateObj.tz(timezone);
  }

  return dateObj.format(format);
}
