













































































































































































































import TitleBar from "@/components/TitleBar/index.vue";
import Vue from "vue";
import ProgramsHeader from "@/components/ProgramsHeader/index.vue";
import SessionSelect from "@/components/SessionSelect/index.vue";
import { ProgramsManager } from "@/services/ProgramsManager";
import { AttendanceManager } from "@/services/AttendanceManager";
import { mdiHumanMaleChild, mdiCameraOff } from "@mdi/js";
import Program from "@/Classes/Program";
import Session from "@/Classes/Session";
import Guest from "@/Classes/Guest";
import Attendance from "@/Classes/Attendance";
import RegisterGuestForm from "@/components/RegisterGuestForm/index.vue";
import PullUp from "@/components/PullUp/index.vue";
import RegisterAttendeeForm from "@/components/RegisterAttendeeForm/index.vue";
import ProgramSessionChecklistCard from "@/components/ProgramSessionChecklist/ProgramSessionChecklistCard.vue";
import BackButton from "@/components/BackButton/index.vue";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "AppProgramsAttendanceController",
  components: {
    BackButton,
    ProgramSessionChecklistCard,
    RegisterAttendeeForm,
    PullUp,
    RegisterGuestForm,
    TitleBar,
    ProgramsHeader,
    SessionSelect,
  },
  data() {
    return {
      under18Icon: mdiHumanMaleChild,
      allowPhotosIcon: mdiCameraOff,
      program: {} as Program,
      programName: "",
      loading: false as boolean,
      loadingAttendees: false as boolean,
      showAddGuestForm: false,
      showRegisterAttendeeForm: false,
      checkInloading: false as boolean,
      sessions: [] as any[],
      sessionId: "" as string,
      activeSession: null as null | Session,
      attendance: [] as any[],
      guestAttendees: [] as Guest[],
      errors: [] as string[],
      headers: [
        {
          text: "Checked In",
          value: "isCheckedIn",
        },
        {
          text: "Name",
          value: "enrolmentName",
        },
        {
          text: "Icons",
          value: "icons",
        },
        {
          text: "NIN",
          value: "enrolmentNin",
          align: "end",
        },
      ],
    };
  },
  computed: {
    breadcrumbs(): Array<{ text: string; disabled: boolean }> {
      return [
        {
          text: "Programs",
          disabled: true,
        },
        {
          text: this.programName,
          disabled: true,
        },
        {
          text: "Attendance",
          disabled: true,
        },
      ];
    },
    bannerImage(): string | null {
      return this.program?.attributes?.bannerImageUrl;
    },
    programId(): string {
      return this.$route.params.id;
    },
    noDataText(): string {
      if (!this.sessionId) {
        return "Please select a session";
      } else {
        return "No attendees found";
      }
    },
    noOfGuestAttendees(): number {
      // look at each item and get quantity attribute
      let total = 0;
      this.guestAttendees.forEach((item: Guest) => {
        total += item.attributes.quantity;
      });
      return total;
    },
    noOfEnrolments(): number {
      if (this.activeSession === null) {
        return 0;
      }

      return this.attendance.length;
    },
    noOfCheckedIn(): number {
      if (this.activeSession === null) {
        return 0;
      }
      let count = 0;
      for (let i in this.attendance) {
        if (Object.prototype.hasOwnProperty.call(this.attendance, i)) {
          if (this.attendance[i].isCheckedIn) {
            count++;
          }
        }
      }
      return count;
    },

    totalAttending(): number {
      return this.noOfCheckedIn + this.noOfGuestAttendees;
    },
  },
  created() {
    this.load();
  },
  methods: {
    onGuestRegistered() {
      this.load();
      this.showAddGuestForm = false;
    },

    onEnrolmentRegistered() {
      this.load();
      this.showRegisterAttendeeForm = false;
    },

    /* Load the program details and it's sessions */
    load(fullLoad = true) {
      if (fullLoad) {
        this.loading = true;
      } else {
        this.loadingAttendees = true;
      }
      const programsManager = new ProgramsManager();
      programsManager
        .getProgram(this.programId)
        .then((program) => {
          this.program = program;
          this.programName = program.attributes.title ?? "";
          if (fullLoad && this.sessionId !== "") {
            this.onSessionChange(this.sessionId);
          }
        })
        .catch((response) => {
          if (fullLoad) {
            this.loading = false;
          } else {
            this.loadingAttendees = false;
          }

          this.errors = ApiResponseHelper.getErrorMessagesFromResponse(
            response
          );
        })
        .finally(() => {
          if (fullLoad) {
            this.loading = false;
          } else {
            this.loadingAttendees = false;
          }
        });
    },

    onSessionChange(sessionId: string, reloadProgram = false) {
      this.activeSession = null;
      this.sessionId = sessionId;

      this.load(false);

      // find the session id in this.program.attributes.sessions
      const session = this.program.attributes.sessions.find(
        (session: Session) => session.id === this.sessionId
      );
      this.activeSession = session as Session;
      const attendance = session?.attributes.attendance || [];
      this.attendance = attendance.map((attendance: Attendance) => {
        return {
          isCheckedIn: attendance.attributes.isCheckedIn,
          enrolmentName: `${attendance.attributes.enrolmentFirstName} ${attendance.attributes.enrolmentLastName}`,
          icons: {
            enrolmentUnder18: attendance.attributes.enrolmentUnder18,
            enrolmentPhotoOptOut: attendance.attributes.enrolmentPhotoOptOut,
          },
          enrolmentNin: attendance.attributes.enrolmentNin,
          enrolmentId: attendance.attributes.enrolmentId,
          hasAttendance: attendance.attributes.hasAttendance,
        };
      });
      this.attendance = this.attendance.filter(
        (attendance: any) => attendance.hasAttendance
      );

      // get the guest attendees
      this.guestAttendees = session?.attributes.guests || [];
    },

    /* Check in the attendee for the session */
    checkIn(attendance: any) {
      const addCheckIn = attendance.isCheckedIn;
      const enrolmentId = attendance.enrolmentId;

      if (!this.sessionId || !enrolmentId) {
        return;
      }

      const attributes = {
        enrolmentId: enrolmentId,
      };

      this.checkInloading = true;
      const attendanceManager = new AttendanceManager();

      if (addCheckIn) {
        attendanceManager
          .addCheckInEnrolmentForSession(
            this.programId,
            this.sessionId,
            attributes
          )
          .then(() => {
            // update the attendance table
            const attendance = this.attendance.find(
              (attendance: Attendance) =>
                attendance.attributes.enrolmentId === enrolmentId
            );
            if (attendance) {
              attendance.isCheckedIn = true;
            }
          })
          .catch((error) => {
            this.errors = ApiResponseHelper.getErrorMessagesFromResponse(error);
          })
          .finally(() => {
            this.checkInloading = false;
          });
      } else {
        attendanceManager
          .removeCheckInEnrolmentForSession(
            this.programId,
            this.sessionId,
            attributes
          )
          .then(() => {
            // update the attendance table
            const attendance = this.attendance.find(
              (attendance: Attendance) =>
                attendance.attributes.enrolmentId === enrolmentId
            );
            if (attendance) {
              attendance.isCheckedIn = true;
            }
          })
          .catch((error) => {
            this.errors = ApiResponseHelper.getErrorMessagesFromResponse(error);
          })
          .finally(() => {
            this.checkInloading = false;
          });
      }
    },

    /* Push to Register attendee for the session */
    registerAttendee() {
      // check if sessionId is not empty
      if (this.sessionId === "") {
        return;
      }

      // change route to register attendee
      this.$router.push({
        name: "app-programs-attendance-register",
        params: {
          registrationType: "attendee",
          sessionId: this.sessionId,
          programId: this.programId,
        },
      });
    },

    /* Push to Register guest for the session */
    registerGuest() {
      // check if sessionId is not empty
      if (this.sessionId === "") {
        return;
      }

      // change route to register guest
      this.$router.push({
        name: "app-programs-attendance-register",
        params: {
          registrationType: "guest",
          sessionId: this.sessionId,
          programId: this.programId,
        },
      });
    },
  },
});
