
import Vue from "vue";
import {
  ProgramSessionChecklistDetailsPayload,
  ProgramsManager,
} from "@/services/ProgramsManager";
import ProgramSessionChecklistCompleteForm from "@/components/ProgramSessionChecklist/ProgramSessionChecklistCompleteForm.vue";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import { mdiClose, mdiAlert, mdiCheck } from "@mdi/js";
import { formatDate } from "@/lib/DateHeler";

export default Vue.extend({
  name: "ProgramSessionChecklistCard",
  components: { ProgramSessionChecklistCompleteForm },
  props: {
    programId: {
      type: String,
      required: true,
    },

    sessionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadError: null as string | null,
      checklistDetails: null as ProgramSessionChecklistDetailsPayload | null,
      icons: {
        alert: mdiAlert,
        close: mdiClose,
        success: mdiCheck,
      },
      showChecklistFormDialog: false,
    };
  },
  watch: {
    sessionId: {
      immediate: true,
      handler() {
        this.loadChecklistDetails();
      },
    },

    programId: {
      immediate: true,
      handler() {
        this.loadChecklistDetails();
      },
    },

    showChecklistFormDialog(nv) {
      if (true === nv) {
        const checklistFormRef = this.getChecklistCompleteFormRef();

        if (checklistFormRef) {
          checklistFormRef.loadForm();
        }
      }
    },
  },
  methods: {
    formatDate(date: string): string {
      return formatDate(date, "MMM DD, YYYY hh:mm A");
    },

    loadChecklistDetails(): void {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.loadError = null;
      this.checklistDetails = null;
      const programsManager = new ProgramsManager();

      programsManager
        .getProgramSessionChecklistDetails(this.programId, this.sessionId)
        .then((payload) => {
          this.checklistDetails = payload;
        })
        .catch((response) => {
          const errors = ApiResponseHelper.getErrorMessagesFromResponse(
            response
          );

          if (0 < errors.length) {
            this.loadError = errors[0];
          } else {
            this.loadError =
              "An unexpected error occurred trying to load the checklist details.";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getChecklistCompleteFormRef(): InstanceType<
      typeof ProgramSessionChecklistCompleteForm
    > | null {
      if (!this.$refs.checklistCompleteForm) {
        return null;
      }

      return this.$refs.checklistCompleteForm as InstanceType<
        typeof ProgramSessionChecklistCompleteForm
      >;
    },

    onChecklistCompleted(): void {
      this.loadChecklistDetails();
      this.showChecklistFormDialog = false;

      const checklistFormRef = this.getChecklistCompleteFormRef();

      if (checklistFormRef) {
        checklistFormRef.clear();
      }
    },

    onCloseChecklistCompleteDialog(): void {
      this.showChecklistFormDialog = false;

      const checklistFormRef = this.getChecklistCompleteFormRef();

      if (checklistFormRef) {
        checklistFormRef.clear();
      }
    },
  },
});
