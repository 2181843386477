
import Vue from "vue";
import { mdiChevronLeft } from "@mdi/js";

export default Vue.extend({
  name: "BackButton",
  props: {
    label: {
      type: String,
      required: false,
      default: "Back",
    },
  },
  data() {
    return {
      backButtonIcon: mdiChevronLeft,
    };
  },
});
