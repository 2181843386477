



















import Vue from "vue";
import { mdiClose } from "@mdi/js";

export default Vue.extend({
  name: "PullUp",
  props: {
    title: {
      type: String,
      required: true,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      closeIcon: mdiClose,
    };
  },
});
