
































































































































import Vue from "vue";
import { mdiAccountSearch } from "@mdi/js";
import { AttendanceManager } from "@/services/AttendanceManager";
import { LooseObject } from "@/types";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "RegisterAttendeeForm",
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reclinkParticipantId: "" as string,
      firstName: "" as string,
      lastName: "" as string,
      email: "" as string,
      mobileNo: "" as string,
      dialog: false as boolean,
      memberSearchIcon: mdiAccountSearch,
      searchError: [] as string[],
      invitationError: [] as string[],
      loading: false as boolean,
      invitationLoading: false as boolean,
      searchResults: {} as LooseObject,
    };
  },
  computed: {
    noOfMembersText(): string {
      const noOfMembers = Object.keys(this.searchResults).length;
      if (noOfMembers === 1) {
        return "1 member was";
      } else {
        return `${noOfMembers} members were`;
      }
    },
  },
  methods: {
    search() {
      this.searchError = [];
      if (this.reclinkParticipantId === "") {
        this.searchError.push("Please enter a Reclink Participant ID");
        return;
      }

      this.loading = true;
      this.dialog = true;
      const attendanceManager = new AttendanceManager();
      attendanceManager
        .enrolmentSearch(this.programId, this.reclinkParticipantId)
        .then((response) => {
          this.reclinkParticipantId = "";
          this.searchResults = response;
        })
        .catch((error) => {
          this.reclinkParticipantId = "";
          this.searchResults = {};
          this.loading = false;
          this.searchError = ApiResponseHelper.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendInvitation() {
      this.invitationError = [];
      if (this.firstName === "") {
        this.invitationError.push("Please enter a first name");
        return;
      }

      if (this.lastName === "") {
        this.invitationError.push("Please enter a last name");
        return;
      }

      if (this.email === "" && this.mobileNo === "") {
        this.invitationError.push("Please enter an email or mobile number");
        return;
      }

      const attributes = {
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddress: this.email ?? null,
        mobileNumber: this.mobileNo ?? null,
      };
      this.invitationLoading = true;
      const attendanceManager = new AttendanceManager();
      attendanceManager
        .createInvitation(this.programId, attributes)
        .then(() => {
          this.$emit("invitation-sent");
        })
        .catch((error) => {
          this.invitationLoading = false;
          this.invitationError = ApiResponseHelper.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.invitationLoading = false;
        });
    },
    registerAttendee(memberProfileId: string, allSessions: boolean) {
      this.loading = true;
      const attributes = {
        memberProfileId: memberProfileId,
        allSessions,
      };
      const attendanceManager = new AttendanceManager();
      attendanceManager
        .addEnrolmentToProgramByClubMemberProfile(
          this.programId,
          this.sessionId,
          attributes
        )
        .then(() => {
          // reset the search results
          this.searchResults = {};
          this.$emit("enrolment-added");
        })
        .catch((error) => {
          // reset the search results
          this.searchResults = {};
          this.loading = false;
          this.dialog = false;
          this.searchError = ApiResponseHelper.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loading = false;
          this.dialog = false;
        });
    },
  },
});
